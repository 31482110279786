<template>
  <b-card-code>
    <b-card-body class="rows-responsive">
      <basic-table
        :fields="fields"
        :data="items"
        :button-table="nameButton"
        :settings="settings"
        :is-busy="isBusy"
        @onUpdate="onUpdate"
        @changePage="onPagination"
        @onPerPage="onPerPage"
        @search="search"
      />
    </b-card-body>
  </b-card-code>
</template>

<script>

import { BCardBody } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@/views/drs/components/BCardCode.vue'
import dbStock from '@/models/stock'
import BasicTable from '@/views/drs/components/BasicTable.vue'

export default {
  components: {
    BasicTable,
    BCardCode,
    BCardBody,
  },
  data() {
    return {
      // nameButton: 'stocks.new_stock',
      /* eslint-disable global-require */
      fields: [
        // eslint-disable-next-line object-curly-newline
        { key: 'stock_id', label: 'stocks.id', sortable: true },
        { key: 'product_name', label: 'stocks.product_name', sortable: true },
        { key: 'product_code', label: 'stocks.product_code', sortable: true },
        { key: 'stocks_updated_at', label: 'stocks.updated_at', sortable: true },
        { key: 'mechanic', label: 'stocks.mechanic', sortable: true },
        { key: 'units', label: 'stocks.units', sortable: true },
      ],
      items: [],
      nameButton: 'Search',
      settings: {},
      isBusy: false,
      perPage: 50,
      searchItem: '',
    }
  },
  async created() {
    if (this.$route.query.page) {
      this.fetch(Number(this.$route.query.page))
    } else {
      this.fetch(1)
    }
  },
  methods: {
    async fetch(page) {
      this.isBusy = true
      const response = await dbStock.get({
        page,
        perPage: this.perPage,
        paginate: true,
        filters: { word: this.searchItem },
      })
      this.isBusy = false
      this.items = JSON.parse(JSON.stringify(response.data))
      delete response.data
      this.settings = response
    },
    onPagination(page) {
      this.fetch(page)
      this.$router.replace({ query: { page } })
    },
    onPerPage(size) {
      this.perPage = size
      this.fetch(1)
      this.$router.replace({ query: { page: 1 } })
    },
    async onUpdate(data) {
      const obj = {
        id: data.stock_id,
        product: data.product_id,
        units: data.units,
        mechanic: data.mechanic,
      }
      this.isBusy = true
      try {
        await dbStock.update(obj)
        this.fetch(Number(this.$route.query.page) || 1)
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.text.success')}`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.text.error')}`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      this.isBusy = false
    },
    search(item) {
      this.searchItem = item
      if (this.searchItem.length > 2 || this.searchItem.length === 0) this.fetch(1)
    },
  },
}
</script>
